.cart-page img {
  max-height: 100px;
}

.close-button {
  background-color: transparent;
  border: none;
  text-align: end;
  color: #808080;
}
