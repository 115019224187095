.product-page .image-wrapper {
  width: 400px;
  height: 400px;
  justify-content: center;
}

.product-page .image-wrapper img {
  height: 100%;
}

.product-page .image-wrapper img {
  height: 100%;
}

@media (max-width: 768px) {
  .product-page .image-wrapper {
    width: 100%;
    height: auto;
    justify-content: start;
  }

  .product-page .image-wrapper img {
    width: 100%;
    height: auto;
  }

  .product-page .product-info {
    flex-direction: column;
  }

  .product-page .product-details {
    margin-top: 1.5rem;
  }
}
