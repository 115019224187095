.product-item img {
  max-width: 200px;
  max-height: 200px;
}

.product-item:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.empty-heart,
.remove {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 10px;
  cursor: pointer;
}

#empty-heart {
  cursor: pointer;
}
